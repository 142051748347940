<template>
  <div class="root">
    <div class="serviceCenterPc___2Zxws">
      <div class="top___1Og-k">
        <div class="serviceCenterPcTitle___dUfF8">
          <img
            src="../img/logo-i.png"
            alt=""
            style="width: 60px;"
            class="logo"
          />
          <span class="logo1">聚信人和</span>
          <div class="circle___fwqzy"></div>
          <div class="title___3INY4">用户服务协议</div>
        </div>
      </div>
      <div class="bottom___1NSEv">
        <div class="serviceCenterPcContent___ZpedD">
          <div class="rulesDetail___3_rBE">
            <div class="rulesDetailBox___19ABP">
              <div class="mainContainer___3S5zd">
                <div id="trainDetail" class="content___385ZG">
                  <p style="text-align: center" align:center>
                    <strong>用户服务协议</strong>
                  </p>
                  <p>
                    我们非常注重保护您的个人信息。为了以简洁、清晰、易懂的方式让您了解聚信人和如何收集和使用您的个人信息情况，我们拟定如下内容，供您快速知悉了解。
                  </p>
                  <p style="text-align: left">
                    <strong>一、我们如何收集和使用您的个人信息</strong>
                  </p>
                  <p style="text-align: left">
                    <strong>【注册信息】</strong>
                  </p>
                  <p>
                    <strong>收集个人信息的类型：</strong
                    ><span
                      >您需要向我们提供您准备使用的用户名及您本人的手机号码，我们将通过发送短信验证码的方式来验证您的身份是否有效。</span
                    >
                  </p>
                  <p>
                    <strong>使用场景及目的：</strong
                    ><span>您注册、登录聚信人和的账户</span>
                  </p>
                  <p><strong>【信息发布】</strong></p>
                  <p>
                    <strong>收集个人信息的类型：</strong
                    ><span
                      >当您使用发布功能时，我们会收集您的个人信息（信息品类不同，收集的信息不一样），包括
                      <strong
                        >姓名、联系方式、个人信息资料、地理位置、用户相册、邮箱、地址信息</strong
                      >
                      。</span
                    >
                  </p>
                  <p>
                    <strong>使用场景及目的：</strong
                    ><span
                      >聚信人和平台最主要的功能就是信息发布功能，您可以根据您的需要选择发布信息的种类（招聘、黄页、房产、二手车、二手物品），填写完整信息后您可以进行信息的发布。</span
                    >
                  </p>
                  <p><strong>【您使用过程中主动提供的信息】</strong></p>
                  <p>
                    <strong>使用场景及目的：</strong
                    ><span
                      >在您使用我们提供的产品/服务过程中，我们根据您使用的特定场景可能会收集您提供的个人信息，以实现您所需要使用的功能。</span
                    >
                  </p>
                  <p>
                    <strong>收集个人信息的类型：</strong
                    ><span
                      >我们会根据具体的场景请求
                      <strong
                        >您提供对应的信息，该类信息主要为您根据自己的意愿自行填写的个人信息</strong
                      >
                      ，我们会严格依法保护您的个人信息。例如您在投递简历的时候，自主创建并填写的简历信息；您在使用我们的金融服务场景时自主填写的姓名、身份证号信息。此外，如果您愿意额外补充您的性别、生日、喜好、常居地等个人资料，将有助于我们为您提供更加个性化的服务，但如果您不提供该等信息，不会影响您使用我们产品/服务的基本功能。</span
                    >
                  </p>
                  <p><strong>【其他】</strong></p>
                  <p>
                    <span
                      >除法律另有规定外，我们仅会在实现目的所必需的
                      <strong>最短时间</strong> 内留存您的相关个人信息。</span
                    >
                  </p>
                  <p style="text-align: left">
                    <strong>二、我们如何共享您的个人信息</strong>
                  </p>
                  <p>
                    <span
                      >我们不会与聚信人和以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：</span
                    >
                    <strong
                      >在获得明确同意的情况下；根据法律法规的规定和行政、司法机构的要求；与我们的关联公司共享；与授权合作伙伴共享：仅为实现本隐私政策中声明的目的，</strong
                    >
                    <span
                      >我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。</span
                    >
                  </p>
                  <p>
                    <strong
                      >对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。</strong
                    >
                  </p>
                  <br>
                  <br>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div>
        <a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >冀ICP备20010751号</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >冀ICP备20010751号-2</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >津文执网文【2019】</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          rel="nofollow"
          >河北坤洲网络科技有限公司</a
        ><span>|</span
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          >联系我们</a
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.serviceCenterPc___2Zxws {
  min-height: 100vh;
  background-color: #f6f6f6;
}
div {
  box-sizing: border-box;
}
div {
  display: block;
}
.logo1 {
  display: flex;
  font-size: 20px;
  font-weight: 800;
  margin-top: -45px;
  margin-left: 20px;
  color: rgba(177, 171, 171, 0.966);
}
.serviceCenterPc___2Zxws .top___1Og-k {
  width: 100%;
  height: 112px;
  background: #fff;
  border-bottom: 1px solid #ddd;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.logo {
  display: block;
  margin-left: -50px;
  margin-top: 20px;
}
.serviceCenterPc___2Zxws
  .top___1Og-k
  .serviceCenterPcTitle___dUfF8
  .circle___fwqzy {
  width: 6px;
  height: 6px;
  background: #595757;
  border-radius: 50%;
  margin: 0 120px;
  margin-top: -15px;
}

.title___3INY4 {
  font-size: 34px;
  line-height: 40px;
  color: #595757;
  margin-top: -25px;
  margin-left: 150px;
}
.serviceCenterPc___2Zxws .bottom___1NSEv {
  width: 100%;
  height: calc(100% - 112px);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.serviceCenterPc___2Zxws .bottom___1NSEv .serviceCenterPcContent___ZpedD {
  width: 1190px;
  height: 100%;
  margin-top: 24px;
  margin-bottom: 64px;
}
.rulesDetail___3_rBE {
  width: 100%;
}
.rulesDetail___3_rBE .rulesDetailBox___19ABP {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.rulesDetail___3_rBE .rulesDetailBox___19ABP .mainContainer___3S5zd {
  width: 880px;
  height: 100%;
  background-color: #fff;
  padding: 32px 32px 0;
}
.rulesDetail___3_rBE
  .rulesDetailBox___19ABP
  .mainContainer___3S5zd
  .content___385ZG {
  width: 100%;
  min-height: 380px;
  padding: 16px 24px;
  background: #fbfbfb;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  line-height: 30px;
  word-break: break-all;
  word-wrap: break-word;
}
.bottom {
  overflow: hidden;
  width: 100%;
  height: 150px;
  /* background-color: red; */
  /* border: 1px solid black; */
  margin: 0 auto;
  line-height: 16px;
  text-align: center;
  color: #999;
}
.bottom div {
  height: 50px;
  line-height: 50px;
  width: 100%;
  margin-top: 90px;
  border-top: 1px solid #999;
}
.bottom div span {
  margin: 0 10px;
  color: #eee;
}

.bottom div a {
  color: #999;
  font-size: 13px;
  /* margin-left: 20px; */
}
</style>